import {
  ContentContainer,
  Heading,
  HeadingContainer,
  PageContainer,
  Stack,
  StyledPrimaryButton,
  StyledSecondaryButton,
  Text,
  Threshold,
} from 'fitify-ui-onboarding/src/components'
import NativeImage from 'fitify-ui-onboarding/src/components/Image/NativeImage'
import { useAuth } from 'fitify-ui-onboarding/src/hooks/useAuth'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OnboardingImageData, getImageProps } from 'utils/images'

import { HomePageProps } from '../types'

export default function Page({ t, actions, initialLocale }: HomePageProps) {
  const { isLoggedIn } = useAuth()
  const { i18n } = useTranslation()

  const imageProps = useMemo<OnboardingImageData>(() => {
    const imageProps = getImageProps(
      'home',
      'youngCoupleFullLocalized',
      initialLocale || i18n.language
    ) as OnboardingImageData
    return imageProps
  }, [i18n.language, initialLocale])

  return (
    <PageContainer>
      <HeadingContainer xs="1rem">
        <Heading>{t('welcome_title')}</Heading>
        <Text textAlign="center" color="blue75">
          {t('welcome_subtitle')}
        </Text>
      </HeadingContainer>

      <ContentContainer>
        <NativeImage {...imageProps} alt="Homepage couple" isResponsive />
        <Stack xs="2rem">
          <Threshold hasBackground>
            <StyledPrimaryButton onClick={actions.handleContinue}>
              {t('btn_continue')}
            </StyledPrimaryButton>
          </Threshold>

          {!isLoggedIn && (
            <Stack xs="1rem" align="center">
              <Text>{t('welcome_have_account_question')}</Text>
              <StyledSecondaryButton size="md" onClick={actions.handleLogin}>
                {t('email_login_forgot_btn_sign_in')}
              </StyledSecondaryButton>
            </Stack>
          )}
        </Stack>
      </ContentContainer>
    </PageContainer>
  )
}
